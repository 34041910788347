body {
    margin: 0;
  }
  
  .wave {
    position: absolute;
    /* left: -180px; */
    bottom: 0;
    /*width: 200px;*/
    /*height: 200px;*/
    /*width: 100%;*/
    /*border-radius: 50%;*/
    /*background:rgba(0,0,0, .3);*/
    z-index: -10;
    width: 100%;
    height: 270px;
    background: url("../public/assets/ola.png") center bottom no-repeat;
  
  
  }
  #fondo {
      background-image: url(https://firebasestorage.googleapis.com/v0/b/autoescuelaclickanddrive.appspot.com/o/4.jpg?alt=media&token=7e4915c8-a723-4e5c-b74b-c3d07ed8021e);
      /* padding: 400px 100px; */
      text-align: center;
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
      position: relative;
  }
  #fondo:before {
      content:'';
      position: absolute;
          top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(0,0,0,0.6);
  }
  #fondo p {
      font-size: 45px;
      color: #fff;
      position: relative
  }
  video {
    object-fit: cover;
    height: 524px;
    width: 100%;
  }
  